
import React from 'react'
import { List, Footer, Ellipsis, NavBar, Swiper, Image, Tabs, Result, Steps,Space } from 'antd-mobile'
import { createFromIconfontCN } from '@ant-design/icons';
import { EnvironmentOutline,TeamOutline,PhonebookOutline   } from 'antd-mobile-icons'
import './index.scss';

const IconFont = createFromIconfontCN({
  scriptUrl: '//at.alicdn.com/t/c/font_4335219_nhipwl2o8u8.js',
});


const { Step } = Steps
const G1menu = () => {
  const token = JSON.parse(localStorage.getItem('cx315_token'))
  return <>
    <NavBar back={null} style={{
      '--height': '40px',
      background: '#02af6f',
      color: '#FFF',
      // '--border-bottom': '1px #eee solid',
    }}>
      {token.ttm}
    </NavBar>
    <Swiper autoplay>
      {token.ys_bn[0].bsh.map((k) => <Swiper.Item key={`topimg${k.xh}`}><Image src={k.baimg} /></Swiper.Item>)}


    </Swiper>


    <Tabs style={{ '--active-title-color': '#02af6f', '--active-line-color': '#02af6f', '--content-padding': '0px' }} >
      <Tabs.Tab title='追溯信息'
        // style={{'--active-title-color':'#02af6f','--active-line-color':'#02af6f'}}
        key='m1'>

        <List style={{ '--font-size': '1em' }}>
          <List.Item extra={token.resq.dbcode} key='a1'> 单元识别码</List.Item>


          <List.Item description={<Ellipsis
            direction='end'
            content={`http://ddspp.cn/G/${token.resq.dbcode}`}
            expandText='展开'
            collapseText='收起'
            key='a2'
          />} >
            追溯网址
          </List.Item>
          <List.Item extra={token.dbno} key='a3'> 数据批次</List.Item>
          <List.Item extra={token.dblx} key='a4'> 数据类型</List.Item>
          {token.glsum && <List.Item extra={token.glsum} key='a5'> 关联数量</List.Item>}

        </List>

        
        { token.qjson[0] !== undefined  && token.qjson[0].qdxx  &&  <><div style={{
              width: '400px',
              height: '39px',
              background: '#02af6f',
              backgroundImage: 'linear-gradient(to right, #02af6f, #fff)',
              color: '#FFF',
              lineHeight: '39px', paddingLeft: '7px'
              // '--border-bottom': '1px #eee solid',
            }}>
    
              流转信息
            </div>
        

            <Steps direction='vertical'>

            {token.qjson[0].qdxx.map((k,i) =>    

              <Step
                title={<Space style={{fontSize:'1.2em'}}><EnvironmentOutline color='#02af6f' fontSize={24}/>{k.qd_qy} <TeamOutline color='#02af6f' fontSize={24}/>{k.qd_mc} <PhonebookOutline  color='#02af6f' fontSize={24} onClick={() => { window.location.href = `tel:${k.qd_phone}` }} /></Space>}
                status='finish'
                description={`完成时间：${k.dtime} `}
                key={`lzl${i}`}
              />
            )}
              <Step
                title='库房发货'
                status='finish'
                key='lza1'
              />


            </Steps>
</>

          }
      


        <div style={{
          width: '400px',
          height: '39px',
          background: '#02af6f',
          backgroundImage: 'linear-gradient(to right, #02af6f, #fff)',
          color: '#FFF',
          lineHeight: '39px', paddingLeft: '7px'
          // '--border-bottom': '1px #eee solid',
        }}>

          企业信息
        </div>

        <List style={{ '--font-size': '1em' }}>
          {token.qmsgjson.map((e,index) => <List.Item extra={e.mmsg}  key={`sc${index}`}> {e.title}</List.Item>)}




        </List>

      </Tabs.Tab>

      <Tabs.Tab title='认证信息' key='m3' >

        {token.rzzt === 'Y' && <Result
          icon={<IconFont type="icon-yiyanzheng" style={{
            fontSize: '5em',
          }} />}
          status='success'
          title='已认证主体'
          description='企业认证为核准工商登记信息及对公账户均正确，且对公收款金额验证通过的结果。'
        />}

        {token.rzzt === 'N' && <Result
          icon={<IconFont type="icon-weiyanzheng" style={{
            fontSize: '5em',
          }} />}
          status='warning'
          title='未认证主体'
          description='企业认证到期、过期或者从未进行过企业认证的主体。'
        />}




      </Tabs.Tab>

    </Tabs>



    <Footer
      label='以下无内容'
      content='@ 技术支持 胜聪网 www.ddspp.com'
      className='footer'

    ></Footer>




  </>
}

export default G1menu;